import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'

import {
  getCountries as apiGetCountries,
  getPolicy as apiGetPolicy,
  getCascadingDropDownOptions
} from '@fullfabric/public-api'

import { useCurrentUser } from '@fullfabric/authorization-officer'

import {
  FormAvailabilityAlert,
  SchemableForm,
  isFormOpenForSubmissions
} from '@fullfabric/schemable-forms'

import FormSubmissionSuccessMessage from './Form/FormSubmissionSuccessMessage'
import useHandleSubmit from './Form/useHandleSubmit'
import useFormQuery from './Form/useFormQuery'

import { useQueryOpts } from '../utils/queryOpts'

import '@fullfabric/alma-mater/dist/index.css'

import styles from './styles.module.scss'
import '../i18n'

export default function Form({
  formId,
  preFillData,
  onBeforeFormStarts,
  onFormSubmitted,
  locale
}) {
  const user = useCurrentUser()
  const queryOpts = useQueryOpts()

  const {
    form,
    loading: isFormLoading,
    errored: isFormErrored,
    requestId: formRequestId
  } = useFormQuery(formId, queryOpts)

  const { data: countriesAsOptions } = useQuery(
    ['get-countries'],
    async () => await apiGetCountries(queryOpts)
  )

  const { data: privacyPolicy } = useQuery(
    ['get-policy', 'privacy_policy'],
    async () => await apiGetPolicy('privacy_policy', queryOpts)
  )

  const { data: marketingPolicy } = useQuery(
    ['get-policy', 'marketing_policy'],
    async () => await apiGetPolicy('marketing_policy', queryOpts)
  )

  const isOpen = isFormOpenForSubmissions(
    form?.start_submissions,
    form?.end_submissions
  )

  const [{ isSubmitted, submissionError }, setSubmissionState] = useState({
    isSubmitted: false,
    submissionError: null
  })

  const handleSubmit = useHandleSubmit({
    formId,
    formRequestId,
    redirectTo: form?.redirect_path,
    externalDomainToken: form?.external_domain_token,
    onStartSubmit: () =>
      setSubmissionState((state) => ({ ...state, submissionError: null })),
    onSubmitted: () => {
      setSubmissionState({ isSubmitted: true, submissionError: null })
      if (onFormSubmitted) onFormSubmitted()
    },
    onNotSubmitted: (error) =>
      setSubmissionState((state) => ({ ...state, submissionError: error }))
  })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => onBeforeFormStarts && onBeforeFormStarts(), [])

  const loadCascadingDropDownOptionsClosure =
    (queryOpts) => async (field, parentValue) =>
      getCascadingDropDownOptions(field, parentValue, queryOpts)

  const loadCascadingDropDownOptions =
    loadCascadingDropDownOptionsClosure(queryOpts)

  if (isFormErrored) {
    return (
      <div className="embeddable-form-error">
        Failed to load form. Please double check the form identifier and
        external domain settings.
      </div>
    )
  }

  if (!isFormLoading && !isOpen) {
    return (
      <FormAvailabilityAlert
        openDate={form?.start_submissions}
        closeDate={form?.end_submissions}
        userTimezone={user?.timezoneName}
        userLocale={locale}
      />
    )
  }

  if (isSubmitted) {
    return (
      <FormSubmissionSuccessMessage successMessage={form?.success_message} />
    )
  }

  return (
    <>
      {!isFormLoading && form?.end_submissions && (
        <div className={styles.alertContainer}>
          <FormAvailabilityAlert
            openDate={form?.start_submissions}
            closeDate={form?.end_submissions}
            userTimezone={user?.timezoneName}
            userLocale={locale}
          />
        </div>
      )}

      <SchemableForm
        countriesAsOptions={countriesAsOptions || []}
        data={preFillData}
        error={submissionError}
        errorDetailScope="forms/submission"
        handleSubmit={handleSubmit}
        isLoading={isFormLoading}
        locale={locale}
        marketingPolicy={marketingPolicy}
        privacyPolicy={privacyPolicy}
        sectionClassName="schemable-form-section"
        separatorClassName="schemable-form-separator"
        schema={form?.schema}
        loadCascadingDropDownOptions={loadCascadingDropDownOptions}
        user={user}
      />
    </>
  )
}
