import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import { loadAlmaMaterI18n } from '@fullfabric/alma-mater'
import { loadSchemableFormsI18n } from '@fullfabric/schemable-forms'

import enResource from './i18n/en/locales.json'
import deResource from './i18n/de/locales.json'
import frResource from './i18n/fr/locales.json'
import esResource from './i18n/es/locales.json'
import nlResource from './i18n/nl/locales.json'
import svResource from './i18n/sv/locales.json'
import daResource from './i18n/da/locales.json'

const resources = {
  en: { translation: enResource },
  de: { translation: deResource },
  fr: { translation: frResource },
  es: { translation: esResource },
  nl: { translation: nlResource },
  sv: { translation: svResource },
  da: { translation: daResource }
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    supportedLngs: ['en', 'de', 'fr', 'es', 'nl', 'sv', 'da', 'it'],
    fallbackLng: 'en',
    defaultNS: 'translation',

    keySeparator: false, // we do not use keys in form messages.welcome
    nsSeparator: false, // nor do we use namespace:key

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  })

loadAlmaMaterI18n('translation', i18n)
loadSchemableFormsI18n('translation', i18n)

export { i18n }

export default i18n
