import React, { useCallback } from 'react'
import { Trans } from 'react-i18next'

import { ApiError, submitForm as apiSubmitForm } from '@fullfabric/public-api'

import { useQueryOpts } from '../../utils/queryOpts'

export default function useHandleSubmit({
  redirectTo,
  formId,
  formRequestId,
  externalDomainToken,
  onStartSubmit,
  onSubmitted,
  onNotSubmitted
}) {
  onSubmitted ||= () => {}

  const queryOpts = useQueryOpts()

  return useCallback(
    async (submissionData) => {
      try {
        onStartSubmit && onStartSubmit()

        await apiSubmitForm(formId, submissionData, {
          ...queryOpts,
          externalDomainToken,
          requestId: formRequestId
        })

        if (redirectTo) window.open(redirectTo, 'form-success-redirect-tab')

        onSubmitted()
      } catch (err) {
        onNotSubmitted && onNotSubmitted(parseError(err))
      }
    },
    [
      externalDomainToken,
      formId,
      formRequestId,
      redirectTo,
      queryOpts,
      onSubmitted,
      onStartSubmit,
      onNotSubmitted
    ]
  )
}

function parseError(err) {
  if (err instanceof ApiError && err.status >= 400 && err.status < 500)
    return err.data

  return {
    message: (
      <Trans>
        There was an error in your submission, please try again in a few
        seconds. If the issue persists, please contact us.
      </Trans>
    )
  }
}
