import { useQuery } from 'react-query'

import { getForm as apiGetForm } from '@fullfabric/public-api'

/**
 * Fetches the data for a FF Form.
 *
 * @param {String} formId ID for the form to fetch
 * @param {Object} [queryOpts] Options for the query, forwarded to `react-query`
 * @returns {Object} Object containing the form, a loading status bool, an error
 *                   status bool, and the request ID from the response headers.
 */
export default function useFormQuery(formId, queryOpts = {}) {
  queryOpts = { returnHeaders: ['x-request-id'], ...queryOpts }

  const { data, status } = useQuery(
    ['get-form', formId],
    async () => await apiGetForm(formId, queryOpts)
  )

  const { data: form, headers } = data || {}

  return {
    form,
    loading: status === 'loading',
    errored: status === 'error',
    requestId: headers?.['x-request-id']
  }
}
