import React from 'react'
import { Trans } from 'react-i18next'

import { Text } from '@fullfabric/alma-mater'

import styles from './styles.module.scss'

export default function FormSubmissionSuccessMessage({ successMessage }) {
  return (
    <div className={styles.paddedContainer}>
      <Text
        tag="h2"
        type="h2"
        fontColor="text-base-darkest"
        marginBottom="tiny"
        marginTop="big"
      >
        <Trans>Thank you for filling in the form.</Trans>
      </Text>

      {successMessage && (
        <Text type="h5" fontColor="text-base-darker" className={styles.message}>
          {successMessage}
        </Text>
      )}
    </div>
  )
}
