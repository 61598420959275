import React from 'react'
import ReactDOM from 'react-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import assert from 'assert'

import { i18n } from './i18n'

import Form from './components/Form'

import { QueryOptsProvider } from './utils/queryOpts'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60 * 1000,
      retry: false
    }
  }
})

/**
 * Renders a FullFabric Form somewhere on the page.
 *
 * @param {String} handle Your FullFabric handle.
 * @param {String} formId ID of the Form to be rendered
 * @param {Element} container A DOM element into which the form will be rendered.
 * @param {String} [opts.env] One of "development", "staging" or "production"
 *                            (default).
 * @param {Function} [opts.onBeforeFormStarts] A function that will be called
 *                                             before the form starts rendering.
 * @param {Function} [opts.onFormSubmitted] A function that will be called after
 *                                          the form has been submitted.
 * @param {Object} [opts.preFillData] An object containing data to pre-fill the
 *                                   form with.
 */
export function renderForm(handle, formId, container, opts = {}) {
  assert.equal(typeof 'handle', 'string', 'handle must be a string')
  assert.equal(typeof 'formId', 'string', 'formId must be a string')
  assert.ok(container, 'container must be provided')
  if (opts.locale) {
    i18n.changeLanguage(opts.locale)
  }

  ReactDOM.render(
    <QueryClientProvider client={queryClient}>
      <QueryOptsProvider handle={handle} env={opts.env}>
        <Form formId={formId} {...opts} />
      </QueryOptsProvider>
    </QueryClientProvider>,
    container
  )
}
